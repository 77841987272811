import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import HeroFullWidth from "../components/Hero/HeroFullWidth";
import Testimonials from "../components/Repeating/Testimonials";
import FAQs from "../components/Repeating/FAQ";
import CallToAction from "../components/Repeating/CTA";

const Page = ({ data }) => {
   const heroFullWidthImages = [
      getImage(data.heroFullWidthDesktop.childImageSharp.gatsbyImageData),
      {
         ...getImage(data.heroFullWidthMobile.childImageSharp.gatsbyImageData),
         media: `(max-width: 767px)`,
      },
   ];
   return (
      <Layout>
         <SearchEngineOptimization
            title="Alanna Pearl | Estate Planning Attorney | Conscious Legal"
            description="At Conscious Legal, Alanna Pearl guides families and business owners to make strategic decisions professionally and personally. Learn more."
            openGraphImage={data.openGraphImage.publicURL}
            twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
         />

         <HeroFullWidth
            backgroundImages={heroFullWidthImages}
            backgroundPosition="70% 0%"
            padding="pt-14 md:pt-32 pb-0 md:pb-52"
            textMaxWidth="max-w-xl"
         >
            <h1>Alanna Pearl</h1>
            <p className="text-mobile-lg md:text-lg mb-0">
               Alanna Pearl’s business and estate planning practice guides families and business owners to make strategic decisions professionally and
               personally. In 2007, Alanna launched her legal career in business litigation, and in 2018, founded Conscious Legal. She educates her
               community on asset protection, business management, and creating and preserving a legacy.
            </p>

            <GatsbyImage image={data.alannaHeroMobile.childImageSharp.gatsbyImageData} alt="Alanna Pearl" className="mt-4 md:hidden" />
         </HeroFullWidth>

         <Testimonials headingLevel="h2" />

         <section className="pt-20 md:pt-24 mb-24 md:mb-44">
            <div className="container">
               <div className="grid md:grid-cols-12 md:gap-x-10 lg:gap-x-20 gap-y-10 items-center">
                  <div className="md:col-start-1 md:col-span-5">
                     <GatsbyImage image={data.alannasMission.childImageSharp.gatsbyImageData} alt="About Alanna’s Mission" />
                  </div>
                  <div className="md:col-end-13 md:col-span-7">
                     <h2>About Alanna’s Mission</h2>
                     <p className="text-mobile-lg md:text-lg mb-0">
                        Alanna graduated in the top 10% of her law school class from University of San Diego in 2007. Her mission is to use her
                        top-notch legal and counseling skills to empower her clients, helping them to plan properly and create a firm foundation to
                        reach higher ground. Alanna dedicates much of her time counseling the Legal, Insurance, Financial, and Tax (“LIFT”) issues
                        related to managing a small business.
                     </p>
                  </div>
               </div>
            </div>
         </section>

         <FAQs className="pb-16 md:pb-24" headingLevel="h2" />

         <CallToAction headingLevel="h2" />
      </Layout>
   );
};

export const query = graphql`
   {
      openGraphImage: file(relativePath: { eq: "open-graph/facebook/Meet Alanna_FB.jpg" }) {
         publicURL
      }
      twitterOpenGraphImage: file(relativePath: { eq: "open-graph/twitter/Meet Alanna_TW.jpg" }) {
         publicURL
      }
      heroFullWidthDesktop: file(relativePath: { eq: "meet-alanna/1.0-Hero-Desktop.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
      heroFullWidthMobile: file(relativePath: { eq: "meet-alanna/1.0 Hero meet alanna - mobile.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
      alannaHeroMobile: file(relativePath: { eq: "meet-alanna/1.1 Alanna 02 - mobile.png" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
      alannasMission: file(relativePath: { eq: "meet-alanna/2.0 Alanna 03 - desktop.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
   }
`;
export default Page;
